import { Controller } from "stimulus";
import StimulusReflex from 'stimulus_reflex';

export default class extends Controller {
    connect() {
        StimulusReflex.register(this);
    }

    upvote(event) {
        event.preventDefault();
        const votableType = event.target.dataset.votableType;
        const votableId = event.target.dataset.votableId;
        this.stimulateIfConnectionOpen('VoteReflex#upvote', votableType, votableId);
    }

    downvote(event) {
        event.preventDefault();
        const votableType = event.target.dataset.votableType;
        const votableId = event.target.dataset.votableId;
        this.stimulateIfConnectionOpen('VoteReflex#downvote', votableType, votableId);
    }

    stimulateIfConnectionOpen(reflex, ...args) {
        if (this.isActionCableConnectionOpen()) {
            this.stimulate(reflex, ...args);
        } else {
            setTimeout(() => this.stimulateIfConnectionOpen(reflex, ...args), 1000);
        }
    }

    isActionCableConnectionOpen() {
        return this.application.consumer.connection.isOpen();
    }
}