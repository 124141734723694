// app/javascript/controllers/flash_controller.js

import { Controller } from "stimulus";
import { gsap } from "gsap";

export default class extends Controller {
    connect() {
        this.flashMessages = this.element.querySelectorAll('.flash');

        this.flashMessages.forEach(flash => {
            const tl = gsap.timeline();

            // Start the flash message a bit below the final position
            tl.fromTo(flash, { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 0.4 })
                .to(flash, { opacity: 0, delay: 4 });

            tl.play();
        });
    }
}