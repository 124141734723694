// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
import Flash_controller from "@/controllers/flash_controller";

console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

import '../../assets/stylesheets/application.scss';

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from "../channels/consumer"


import FlashController from "../controllers/flash_controller";
import CategoryController from "../controllers/category_controller";
import VoteController from "../controllers/vote_controller";

const application = Application.start();
application.register("flash", FlashController);
application.register("category", CategoryController);
application.register("vote", VoteController);

// Connects all Reflexes to StimulusReflex
StimulusReflex.initialize(application, { consumer, debug: true })


// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
